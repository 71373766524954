import { render, staticRenderFns } from "./ProductMultipleChanged.vue?vue&type=template&id=18ab8a18&scoped=true"
import script from "./ProductMultipleChanged.vue?vue&type=script&lang=js"
export * from "./ProductMultipleChanged.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18ab8a18",
  null
  
)

export default component.exports