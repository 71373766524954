<template>
  <div>
    <div v-for="(field, fieldIdx) in product['fields']" :key="'f' + fieldIdx">
      <div :class="{prices: !editProduct && !newProduct}" class="zem-collapse-table columns mt-1">
        <div class="zem-collapse-table__column title"></div>
        <div
          v-for="(cell, cellIdx) in field.data.cells"
          :key="'g' + cellIdx"
          class="zem-collapse-table__column title"
        >
          {{ !showColumn(cell) ? cell : '' }}
        </div>
        <div v-if="editProduct || newProduct" class="zem-collapse-table__column title"></div>
        <template v-for="(price, priceIdx) in field.data['prices']">
          <div :key="'h' + priceIdx" class="zem-collapse-table__column title overflow-unset">
            <template v-if="editProduct || newProduct">
              <ZemDropdownList
                v-model="price.data"
                :loading="field.loading"
                :options="product['options']"
                class="mt-0"
                type="object"
                @open="getOptionsForPrices(field)"
              />
            </template>
            <template v-else>
              <span class="text-truncate">{{ price.data.title }}</span>
            </template>
          </div>
          <div v-for="fieldPrice in price.fields" class="zem-collapse-table__column text">
            <template v-if="editProduct || newProduct">
              <zem-input
                v-if="!accessRole(fieldPrice.title)"
                v-model="fieldPrice.value"
                :disabled="accessRole(fieldPrice.title)"
                class="mt-0"
                type="text"
                @input="rateRecalculation(fieldPrice, price)"
              ></zem-input>
            </template>
            <template v-else>
              {{ fieldPrice.value }}
            </template>
          </div>
          <div v-if="editProduct || newProduct" class="zem-collapse-table__column end_delete">
            <div
              v-if="priceIdx > 0 && $can('edit' || null, 'directory-item-delete-region-to-price-list' || null)"
              class="zem-collapse-table__row text text-h cursor-pointer"
              @click="deleteItem(product, priceIdx)"
            >
              <img :src="require('@/assets/icons/close-r.svg')" alt="" class="delete_item" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="(newProduct || editProduct) && $can('edit' || null, 'directory-item-add-region-to-price-list' || null)"
      class="align-center colored colored--link mt-1"
      style="display: inline-flex"
      @click="addItem(product, idx)"
    >
      <img :src="require('@/assets/icons/plus_primary.svg')" alt="" class="mr-1" />
      {{ product['fields'][1].title }}
    </div>
  </div>
</template>

<script>
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import {options} from '@/mixins/options'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import OtherService from '@/services/other.service'

export default {
  mixins: [options],
  components: {
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemRadioButton,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    editProduct: {
      type: Boolean,
      default: false,
    },
    newProduct: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: null,
    },
    pricesMode: {
      type: String,
    },
  },
  methods: {
    accessRole(column) {
      if (column === 'Себестоимость') return !this.$can('edit', 'directory-item-edit-price')
      if (column === 'Наценка, %') return !this.$can('edit', 'directory-item-edit-price-up')
      return !this.$can('edit', 'directory-item-edit-price-customer')
    },
    showColumn(column) {
      if (column === 'Себестоимость') return !this.$can('edit', 'directory-item-show-price')
      if (column === 'Наценка, %') return !this.$can('edit', 'directory-item-show-price-up')
      return !this.$can('edit', 'directory-item-show-price-customer')
    },
    rateRecalculation(field, price) {
      const elements = price.fields
      if (field['input_name'] === 'price') {
        const value = Number(elements[0].value)
        if (value < 0) {
          elements[0].value = 0
        }
        elements[2].value = (elements[0].value / (1 - elements[1].value / 100)).toFixed(2)
      } else if (field['input_name'] === 'price_up') {
        const value = Number(elements[1].value)
        if (value < 0) {
          elements[1].value = 0
        } else if (value > 99.99) {
          elements[1].value = 99.99
        }
        elements[2].value = (elements[0].value / (1 - elements[1].value / 100)).toFixed(2)
      } else if (field['input_name'] === 'price_customer') {
        const percent = ((1 - elements[0].value / elements[2].value) * 100).toFixed(2)
        percent < 0
          ? (elements[1].value = 0)
          : percent > 99.99
          ? (elements[1].value = 99.99)
          : (elements[1].value = percent)
      }
    },
    getOptionsForPrices(el) {
      if (!this.loadOptions.includes(el['api_path'])) {
        el.loading = true
        OtherService.getOptions(el['api_path']).then(r => {
          this.product.options = r.data.data
          this.loadOptions.push(el['api_path'])
          el.loading = false
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-dropdown {
  margin-top: 0;
}
</style>
