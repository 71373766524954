<template>
    <div>
        <div class="d-flex justify-content-between" style="align-items: start;">
            <div>
                <zem-title>Множественное изменение</zem-title>
                <p class="mb-3 mt-1 text-muted text-normal">{{ declOfNum(products.length, words) }}</p>
            </div>
            <div class="d-flex align-center">
                <zem-link
                        class="mr-2 mobile-d-none"
                        @click="cancelProducts"
                        v-if="!isSaving"
                >
                    Отменить
                </zem-link>
                <zem-button
                        class="mr-2 mobile-d-none"
                        @click="saveProducts"
                        :disabled="isSaving"
                >
                    <save-icon size="16" v-if="!isSaving"></save-icon>
                    <zem-preloader v-else color="light" :size="16" class="mr-0"/>
                    <span class="ml-1">Сохранить</span>
                </zem-button>
            </div>
        </div>
        <div class="zem-collapse-table rows">
            <div
                    v-for="(item, index) in product.fields"
                    :key="'a' + index"
                    class="zem-collapse-table__row"
            >
                <div class="zem-collapse-table__column title">{{ item.title }}</div>
                <zem-input v-if="item.type === 'count'" v-model="item.value" class="mt-0" type="number"/>
                <ZemDropdownList
                        class="mt-0"
                        v-else-if="item.type === 'unit'"
                        v-model="item.value" type="object" :options="unitOptions"/>
                <ZemMultiSelect
                        v-else
                        v-model="item.value"
                        type="object"
                        :options="positionOptions"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ZemTitle from "@/components/ui/Title.vue";
import ZemDropdownList from "@/components/ui/ZemDropdownList.vue";
import ZemInput from "@/components/ui/ZemInput.vue";
import ZemMultiSelect from "@/components/ui/ZemMultiSelect.vue";
import OtherService from "@/services/other.service";
import ZemRadioButton from "@/components/ui/ZemRadioButton.vue";
import zemCollapse from "@/components/ui/ZemCollapse.vue";
import ZemLink from "@/components/ui/ZemLink.vue";
import {SaveIcon} from "vue-feather-icons";
import {errorHandler} from "@/assets/scripts/scripts";
import ProductsService from "@/services/products.service";
import ZemPreloader from "@/components/ui/ZemPreloader.vue";
import ZemButton from "@/components/ui/ZemButton.vue";

export default {
    components: {
        ZemButton,
        ZemPreloader,
        ZemLink, ZemRadioButton, ZemMultiSelect, ZemInput, ZemDropdownList, ZemTitle, zemCollapse, SaveIcon
    },
    props: {
        products: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            words: ['продукт', 'продукта', 'продуктов'],
            unitOptions: [],
            positionOptions: [],
            isSaving: false,
            product: {
                fields: [
                    {
                        title: 'Количество',
                        type: 'count',
                        value: null,
                    },
                    {
                        title: 'Ед. измерения',
                        type: 'unit',
                        value: {
                            title: null,
                            value: null
                        }
                    },
                    {
                        title: 'Расположение',
                        type: 'position_group_ids',
                        value: [],
                    },
                ]
            }
        }
    },
    mounted() {
        this.getUnits()
        this.getPositionGroups()
    },
    methods: {
        getUnits() {
            OtherService.getOptions('/v1/units', false)
                .then(r => {
                    this.unitOptions = r.data.data
                })
        },
        getPositionGroups() {
            OtherService.getOptions('/v1/directory/position-groups-tree', false)
                .then(r => {
                    this.positionOptions = r.data.data
                })
        },
        cancelProducts() {
            this.$store.commit('products/itemsForDelete', [])
            this.$store.commit('sidebars/changeRightSidebar', false)
        },
        saveProducts() {
            this.isSaving = true
            const {fields} = this.product

            let newProduct = {}

            if (fields[0].value !== null && fields[0].value !== '') newProduct['count'] = fields[0].value
            if (fields[1].value.value !== null) newProduct['unit_id'] = fields[1].value.value
            if (fields[2].value.length > 0)
                newProduct['position_group_ids'] = fields[2].value.map(position => position.value)

            const data = []

            this.$store.state.products.selectedItemsForDelete.forEach(id => {
                data.push({
                    id,
                    ...newProduct
                })
            })

            ProductsService.updateMultipleDirectoryItems(data)
                .then(r => {
                    this.$toast.success(r.data.message)
                    this.isSaving = false
                    ProductsService.getDirectoryItems()
                })
                .catch(e => {
                    this.isSaving = false
                    errorHandler(e)
                })
        },
        declOfNum(n, text_forms) {
            n = Math.abs(n) % 100;
            const n1 = n % 10;
            if (n > 10 && n < 20) {
                return `${n} ${text_forms[2]}`;
            }
            if (n1 > 1 && n1 < 5) {
                return `${n} ${text_forms[1]}`;
            }
            if (n1 === 1) {
                return `${n} ${text_forms[0]}`;
            }
            return `${n} ${text_forms[2]}`;
        }
    }
}
</script>

<style scoped lang="scss">

</style>